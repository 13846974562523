WebFontConfig = {
    classes: false,
    events: false,
    google: { families: [ 'Roboto:400,700' ] }
};
(function() {
    var wf = document.createElement('script');
    //wf.id = "webfont";
    wf.src = 'https://ajax.googleapis.com/ajax/libs/webfont/1/webfont.js';
    wf.type = 'text/javascript';
    wf.async = 'true';
    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(wf, s);
})();
